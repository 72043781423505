export const listaSelecione = [
  {
    label: "",
    value: "",
  },
];

export const listaEstados = [
  { id: "", estado: "" },
  { id: "AC", estado: "Acre" },
  { id: "AL", estado: "Alagoas" },
  { id: "AM", estado: "Amazonas" },
  { id: "AP", estado: "Amapá" },
  { id: "BA", estado: "Bahia" },
  { id: "CE", estado: "Ceará" },
  { id: "DF", estado: "Distrito Federal" },
  { id: "ES", estado: "Espírito Santo" },
  { id: "GO", estado: "Goiás" },
  { id: "MA", estado: "Maranhão" },
  { id: "MG", estado: "Minas Gerais" },
  { id: "MS", estado: "Mato Grosso do Sul" },
  { id: "MT", estado: "Mato Grosso" },
  { id: "PA", estado: "Pará" },
  { id: "PB", estado: "Paraíba" },
  { id: "PE", estado: "Pernambuco" },
  { id: "PI", estado: "Piauí" },
  { id: "PR", estado: "Paraná" },
  { id: "RJ", estado: "Rio de Janeiro" },
  { id: "RN", estado: "Rio Grande do Norte" },
  { id: "RO", estado: "Rondônia" },
  { id: "RR", estado: "Roraima" },
  { id: "RS", estado: "Rio Grande do Sul" },
  { id: "SC", estado: "Santa Catarina" },
  { id: "SP", estado: "São Paulo" },
  { id: "SE", estado: "Sergipe" },
  { id: "TO", estado: "Tocantins" },
];
export const listaGeneros = [
  ...listaSelecione,
  {
    label: "Feminino",
    value: "feminino",
  },
  {
    label: "Masculino",
    value: "masculino",
  },
  {
    label: "Outros",
    value: "outros",
  },
  {
    label: "Não informado",
    value: "nao_informado",
  },
];
export const listaEstadoCivil = [
  ...listaSelecione,
  {
    label: "Não Informado",
    value: "NI",
  },
  {
    label: "Solteiro(a)",
    value: "solteiro",
  },
  {
    label: "Casado(a)",
    value: "casado",
  },
  {
    label: "Viúvo(a)",
    value: "viuvo",
  },
  {
    label: "Separado(a)",
    value: "separado",
  },
  {
    label: "Divorciado(a)",
    value: "divorciado",
  },
  {
    label: "Amasiado(a)",
    value: "Amasiado",
  },
];

export const listaTipoDemanda = [
  ...listaSelecione,
  {
    value: "SUGESTÃO DE LEI",
    label: "SUGESTÃO DE LEI",
  },
  {
    value: "EDUCAÇÃO",
    label: "EDUCAÇÃO",
  },
  {
    value: "SAÚDE",
    label: "SAÚDE",
  },
  {
    value: "INCLUSÃO",
    label: "INCLUSÃO",
  },
  {
    value: "SEGURANÇA PÚBLICA",
    label: "SEGURANÇA PÚBLICA",
  },
  {
    value: "SOLICITAÇÃO DE MELHORIAS",
    label: "SOLICITAÇÃO DE MELHORIAS",
  },
  {
    value: "MEIO AMBIENTE",
    label: "MEIO AMBIENTE",
  },
  {
    value: "MOBILIDADE",
    label: "MOBILIDADE",
  },
  {
    value: "REPAROS",
    label: "REPAROS",
  },
  {
    value: "DENÚNCIAS",
    label: "DENÚNCIAS",
  },
  {
    value: "RECLAMAÇÕES",
    label: "RECLAMAÇÕES",
  },
];

export const RA = [
  {
    value: "RA I",
    label: "BRASÍLIA",
  },
  {
    value: "RA II",
    label: "GAMA",
  },
  {
    value: "RA III",
    label: "TAGUATINGA",
  },
  {
    value: "RA IV",
    label: "BRAZLÂNDIA",
  },
  {
    value: "RA V",
    label: "SOBRADINHO",
  },
  {
    value: "RA VI",
    label: "PLANALTINA",
  },
  {
    value: "RA VII",
    label: "PARANOÁ",
  },
  {
    value: "RA VIII",
    label: "NÚCLEO BANDEIRANTE",
  },
  {
    value: "RA IX",
    label: "CEILÂNDIA",
  },
  {
    value: "RA X",
    label: "GUARÁ",
  },
  {
    value: "RA XI",
    label: "CRUZEIRO",
  },
  {
    value: "RA XII",
    label: "SAMAMBAIA",
  },
  {
    value: "RA XIII",
    label: "SANTA MARIA",
  },
  {
    value: "RA XIV",
    label: "SÃO SEBASTIÃO ",
  },
  {
    value: "RA XV",
    label: "RECANTO DAS EMAS",
  },
  {
    value: "RA XVI",
    label: "LAGO SUL",
  },
  {
    value: "RA XVII",
    label: "RIACHO FUNDO I",
  },
  {
    value: "RA XVIII",
    label: "LAGO NORTE",
  },
  {
    value: "RA XIX",
    label: "CANDANGOLÂNDIA",
  },
  {
    value: "RA XX",
    label: "ÁGUAS CLARAS",
  },
  {
    value: "RA XXI",
    label: "RIACHO FUNDO II",
  },
  {
    value: "RA XXII",
    label: "SUDOESTE/OCTOGONAL",
  },
  {
    value: "RA XXIII",
    label: "VARJÃO",
  },
  {
    value: "RA XXIV",
    label: "PARK WAY",
  },
  {
    value: "RA XXV",
    label: "SCIA - SETOR COMPLEMENTAR DE INDÚ",
  },
  {
    value: "RA XXVI",
    label: "SOBRADINHO II",
  },
  {
    value: "RA XXVII",
    label: "JARDIM BOTÂNICO",
  },
  {
    value: "RA XXVIII",
    label: "ITAPOÃ",
  },
  {
    value: "RA XXIX",
    label: "SIA - SETOR DE INDÚSTRIA E ABASTEC",
  },
  {
    value: "RA XXX",
    label: "VICENTE PIRES",
  },
  {
    value: "RA XXXI",
    label: "FERCAL",
  },
];
export const listaRA = [...listaSelecione, ...RA];

export const listaRegiao = [
  ...listaSelecione,
  {
    value: "Centro Oeste",
    label: "Centro Oeste",
  },
  {
    value: "Norte",
    label: "Norte",
  },
  {
    value: "Nordeste",
    label: "Nordeste",
  },
  {
    value: "Sul",
    label: "Sul",
  },
  {
    value: "Sudeste",
    label: "Sudeste",
  },
];

export const listaEscolaridade = [
  ...listaSelecione,
  {
    value: "Analfabeto",
    label: "Analfabeto",
  },
  {
    value: "Ensino fundamental incompleto",
    label: "Ensino fundamental incompleto",
  },
  {
    value: "Ensino fundamental completo",
    label: "Ensino fundamental completo",
  },
  {
    value: "Ensino médio incompleto",
    label: "Ensino médio incompleto",
  },
  {
    value: "Ensino médio completo",
    label: "Ensino médio completo",
  },
  {
    value: "Ensino Superior",
    label: "Ensino Superior",
  },
];

export const listaTratamentos = [
  ...listaSelecione,
  {
    value: "Sr.",
    label: "Senhor",
  },
  {
    value: "Sra.",
    label: "Senhora",
  },
  {
    value: "V. Ex.ª",
    label: "Vossa Excelência",
  },
];
export const ROLES = [
  ...listaSelecione,
  {
    value: "ADMIN_USER",
    label: "Usuário Administrador",
  },
  {
    value: "EDITOR_USER",
    label: "Usuário Editor",
  },
  {
    value: "COORDINATOR_USER",
    label: "Usuário Coordenador",
  },
  {
    value: "SUPPORTER_USER",
    label: "Usuário Apoiador",
  },
  {
    value: "PUBLIC_USER",
    label: "Usuário Público",
  },
];

export const PRIORIDADES = [
  ...listaSelecione,
  {
    value: "Baixa",
    label: "Baixa",
  },
  {
    value: "Média",
    label: "Média",
  },
  {
    value: "Alta",
    label: "Alta",
  },
];

export const listaAcessos = [
  { value: 1, label: "ADMINISTRATIVO" },
  { value: 8, label: "AGENDA EDIÇÃO" },
  { value: 2, label: "AGENDA VISUALIZAÇÃO" },
  { value: 3, label: "CONTATOS" },
  { value: 4, label: "DEMANDAS" },
  { value: 9, label: "DEMANDAS GERAL" },
  { value: 5, label: "FERRAMENTAS" },
  { value: 6, label: "ENVIOS WHATSAPP" },
];

export const listaCamposDemandas = [
  {
    titulo: "Protocolo",
    value: "protocolo",
    label: "Protocolo",
  },
  {
    titulo: "Solicitante",
    value: "pessoa.nome",
    label: "Solicitante",
  },
  {
    titulo: "Título",
    value: "titulo",
    label: "Titulo",
  },
  {
    titulo: "Tema/Categoria",
    value: "categorias.nome",
    label: "Tema/Categoria",
  },
  {
    titulo: "Data Abertura",
    value: "data",
    label: "Data de abertura",
  },
  {
    titulo: "Prazo",
    value: "prazoData",
    label: "Prazo",
  },
  {
    titulo: "Descrição",
    value: "descricao",
    label: "Descricao",
  },
  {
    titulo: "Equipe",
    value: "caixas",
    label: "Equipe",
  },
  {
    titulo: "Região",
    value: "regiao",
    label: "Região",
  },
  {
    titulo: "Responsáveis",
    value: "responsaveis",
    label: "Responsáveis",
  },
  {
    titulo: "Status",
    value: "statusGeral.label",
    label: "Status",
  },
  {
    titulo: "Atividades",
    value: "atividades",
    label: "Todas Atividades",
  },
  {
    titulo: "Atividade Recente",
    value: "atividades_last",
    label: "Última Atividade",
  },
];
export const listaCamposContatos = [
  {
    value: "nome",
    label: "Nome/Razão",
    titulo: "NOME",
    group: "Dados Pessoais",
  },
  {
    value: "nomeSocial",
    label: "Nome Social",
    titulo: "Nome Social",
    group: "Dados Pessoais",
  },
  {
    value: "cpfCnpj",
    label: "CPF/CNPJ",
    titulo: "CPF/CNPJ",
    group: "Dados Pessoais",
  },
  {
    value: "nascimento",
    label: "Data de Nascimento",
    titulo: "NASCIMENTO",
    group: "Dados Pessoais",
  },
  {
    value: "sexo",
    label: "Sexo",
    titulo: "SEXO",
    group: "Dados Pessoais",
  },
  {
    value: "estadoCivil",
    label: "Estado Civil",
    titulo: "Estado Civil",
    group: "Dados Pessoais",
  },
  {
    value: "escolaridade",
    label: "Escolaridade",
    titulo: "Escolaridade",
    group: "Dados Pessoais",
  },
  {
    value: "redesSociais.email",
    label: "Email",
    titulo: "EMAIL",
    group: "Contatos",
  },
  {
    value: "listaTelefone",
    label: "Telefone",
    group: "Contatos",
    titulo: "Telefones",
  },
  {
    value: "endereco.uf",
    label: "UF",
    titulo: "UF",
    group: "Endereço",
  },
  {
    value: "endereco.cep",
    label: "CEP",
    titulo: "CEP",
    group: "Endereço",
  },
  {
    value: "endereco.cidade",
    label: "Cidade",
    titulo: "CIDADE",
    group: "Endereço",
  },
  {
    value: "endereco.bairro",
    label: "Bairro",
    titulo: "BAIRRO",
    group: "Endereço",
  },
  {
    value: "endereco.logradouro",
    label: "Logradouro",
    titulo: "LOGRADOURO",
    group: "Endereço",
  },
  {
    value: "endereco.numero",
    label: "Numero",
    titulo: "NUMERO",
    group: "Endereço",
  },
  {
    value: "regiao",
    label: "Região",
    titulo: "Região",
    group: "Endereço",
  },
  {
    value: "grupos",
    label: "Grupos",
    titulo: "GRUPOS",
    group: "Outros",
  },
  {
    value: "obs",
    label: "OBSERVACAO",
    titulo: "OBSERVACAO",
    group: "Outros",
  },
];

export const STATUS_GERAL = [
  ...listaSelecione,
  {
    value: "1",
    label: "ABERTA",
  },
  {
    value: "2",
    label: "EM ANDAMENTO",
  },
  {
    value: "5",
    label: "RESPONDIDA",
  },
  {
    value: "3",
    label: "CANCELADA",
  },
  {
    value: "4",
    label: "FINALIZADA",
  },
  {
    value: "6",
    label: "ACOMPANHAMENTO ESPECIAL",
  },
];

export const STATUS_CHAMADO = [
  {
    value: "1",
    label: "ABERTO",
  },
  {
    value: "2",
    label: "EM ANDAMENTO",
  },
  {
    value: "3",
    label: "RESPONDIDO",
  },
  {
    value: "4",
    label: "FECHADO",
  },
];

export const chamadoCat = [
  {
    value: "1",
    label: "SUGESTÃO",
  },
  {
    value: "2",
    label: "ERROS/TRAVAMENTOS",
  },
  {
    value: "3",
    label: "OUTROS",
  },
];
