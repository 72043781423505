import React, {useContext, useState} from "react";
import GABForm from "../../../../components/gab-form";
import {FormikProps} from "formik";
import GABUpload, {FileUpload} from "../../../../components/gab-upload";
import AppProviderContext, {propsAppProviderContext} from "../../../../app-provider";
import GABDialog from "../../../../components/gab-dialog";


interface propsGABFormDemandaUpload {
    formik:FormikProps<any>;
}
const GABFormDemandaUpload: React.FC<propsGABFormDemandaUpload> = (props) => {
    const {
        postAxios,
        profile,
        setLoading,
        delAxios
    }: propsAppProviderContext = useContext(AppProviderContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [fileDel, setFileDel] = useState<any>();

    const onUpload = async (file: File, result: any, files: FileUpload[]) => {
        try {
            setLoading(true)
            const data = {
                usuario: profile.email,
                nome: file.name,
                arquivo: result,
            }
            const resultUpload = await postAxios('/parlamentar/demanda/arquivo', data);
            if (resultUpload.data) {
                let anexos = props.formik.values.anexos || [];
                anexos.push(resultUpload.data);
                props.formik.setFieldValue('anexos', anexos);
                setLoading(false)
            }
        } catch (e) {
            setLoading(false)
            throw Error("error on upload");
        }
    }
    
    const confDelete = async (file:any) =>{
        setFileDel(file);
        setModalOpen(true);
    }

    const deleteFile = async () =>{
        setLoading(true);
        setModalOpen(false);
        try {
            const newList = props.formik?.values?.anexos.filter((f:any)=>f.caminho !== fileDel!.caminho);
            props.formik.setFieldValue('anexos', newList);
            const data ={
                arquivo : fileDel!.caminho
            }
            await postAxios(`/parlamentar/demanda/arquivo/delete`,data);
        } catch (e) {
        }
        setLoading(false)
    }

  return (
     <>
         <GABForm
             icon={"akar-icons:attach"}
             title={"Anexos"}
             subtile={`Anexar arquivos à demanda`}
         >
             <GABUpload onSelectFile={onUpload} anexos={props.formik?.values?.anexos} deleteFile={confDelete}/>

         </GABForm>
         <GABDialog
            open={modalOpen}
            title="Confirmar Exclusão do arquivo"
            description=""
            labelLeft="cancelar"
            labelRight="Confirmar"
            onClickLeft={() => {
              setModalOpen(false);
            }}
            onClickRight={deleteFile}
          >
            <h3>Deseja remover o arquivo: {fileDel?.nome} ?</h3>
            <h4>Esta ação é irreversível.</h4>
          </GABDialog>
     </>
  );
};

export default GABFormDemandaUpload;
